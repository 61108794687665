import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import { iconPages } from "../data/icon";
import ReactWordcloud from "react-wordcloud";

import { options } from "../data/tag-options";

const isBrowser = typeof window !== "undefined";

const pdfIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1800 1900"
    width="16"
    height="16"
    fill="#336699"
  >
    <path d="M1596 380q28 28 48 76t20 88v1152q0 40-28 68t-68 28H224q-40 0-68-28t-28-68V96q0-40 28-68t68-28h896q40 0 88 20t76 48zm-444-244v376h376q-10-29-22-41l-313-313q-12-12-41-22zm384 1528V640h-416q-40 0-68-28t-28-68V128H256v1536h1280zm-514-593q33 26 84 56 59-7 117-7 147 0 177 49 16 22 2 52 0 1-1 2l-2 2v1q-6 38-71 38-48 0-115-20t-130-53q-221 24-392 83-153 262-242 262-15 0-28-7l-24-12q-1-1-6-5-10-10-6-36 9-40 56-91.5t132-96.5q14-9 23 6 2 2 2 4 52-85 107-197 68-136 104-262-24-82-30.5-159.5T785 552q11-40 42-40h22q23 0 35 15 18 21 9 68-2 6-4 8 1 3 1 8v30q-2 123-14 192 55 164 146 238zm-576 411q52-24 137-158-51 40-87.5 84t-49.5 74zm398-920q-15 42-2 132 1-7 7-44 0-3 7-43 1-4 4-8-1-1-1-2t-.5-1.5-.5-1.5q-1-22-13-36 0 1-1 2v2zm-124 661q135-54 284-81-2-1-13-9.5t-16-13.5q-76-67-127-176-27 86-83 197-30 56-45 83zm646-16q-24-24-140-24 76 28 124 28 14 0 18-1 0-1-2-3z" />
  </svg>
);

const Basic = ({ data, location, pageContext }) => {
  const [displayTag, setDisplayTag] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDisplayTag(true);
    }, 200);
    return () => clearTimeout(timer);
  }, []);

  const { title, subtitle, lang, img, img_link, tag_cloud, icon, one_page_scroll } =
    data.markdownRemark.frontmatter;
  const { slug } = pageContext;
  const { rawMarkdownBody } = data.markdownRemark;
  const { html } = data.markdownRemark;
  const { file } = data.markdownRemark.frontmatter;
  const mainSection = location.pathname.split("/")[lang == "default" ? 1 : 2];
  const menu = {
    main: data.allMainMenuJson.edges,
    service: data.allServiceMenuJson.edges,
    footer: data.allFooterMenuJson.edges,
    subFooter: data.allSubFooterMenuJson.edges,
  };
  let words = [];
  if (
    tag_cloud &&
    tag_cloud != "no" &&
    data &&
    data[tag_cloud] &&
    data[tag_cloud].nodes
  ) {
    words = data[tag_cloud].nodes;
  }

  const path =
    "/" +
    (lang == "default" ? "" : lang + "/") +
    mainSection +
    "/" +
    location.pathname.split("/")[lang == "default" ? 2 : 3];

  console.log("path", path);

  let contextMenu = [];
  let x;
  menu.main.map((e) => {
    if (e.node.submenu) {
      x = e.node.submenu.filter((val) => {
        return val.url == path;
      });
    }
    if (x && x.length > 0) {
      contextMenu = x;
    }
  });

  return (
    <Layout
      bodyClass="page-default-single"
      lang={lang}
      menu={menu}
      pageTitle={title}
      slug={slug}
    >
      <div className="pb-5">
        <div className="title-wrapper">
          {tag_cloud != "no" && isBrowser && window.innerWidth > 960 && displayTag && (
            <div className="tag-background">
              <ReactWordcloud words={words} options={options} />
            </div>
          )}
          <div className="container container-height">
            <div className="row h-100">
              <div className="col-12 col-md-8 my-auto">
                <div className="">
                  <h1 className="title text-white">
                    <strong>{title}</strong>
                  </h1>
                  {subtitle && <h4 className="mt-1 subtitle text-white">{subtitle}</h4>}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-start pt-6">
            <div className="col-12 col-md-8 col-lg-7">
              {img && (
                <div>
                  {img_link ? (
                    <a href={img_link} target="_blank">
                      <img className="img-container mb-4" src={img} />
                    </a>
                  ) : (
                    <img className="img-container mb-4" src={img} />
                  )}
                </div>
              )}
              {one_page_scroll && one_page_scroll == "yes" ? (
                <div className="content" dangerouslySetInnerHTML={{ __html: html }} />
              ) : (
                <div className="content">
                  <ReactMarkdown>{rawMarkdownBody}</ReactMarkdown>
                </div>
              )}
            </div>

            <div className="col-12 col-md-4 col-lg-4 offset-lg-1">
              {isBrowser && window.innerWidth > 960 && (
                <div className="sidebar">
                  {icon && <div className="mb-3"> {iconPages[icon]}</div>}
                  {contextMenu && contextMenu[0] && contextMenu[0].anchors && (
                    <div className="info-title-container">
                      <div className="info-title">{title}</div>
                      <div id="info-menu" className="info-menu">
                        {contextMenu[0]?.anchors?.map((link, id) => (
                          <div className="pt-1" key={id}>
                            {one_page_scroll && one_page_scroll == "yes" ? (
                              <Link to={link.url}>{link.name}</Link>
                            ) : (
                              <Link to={link.url} activeClassName="activeLink">
                                {" "}
                                {link.name}
                              </Link>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              )}
              {file && (
                <div
                  className={icon ? "info-title-container mt-4" : "info-title-container"}
                >
                  <div className="info-title">FILE</div>
                  <div id="info-menu" className="info-menu pt-1">
                    <span className="mr-1">{pdfIcon}</span>
                    <a href={file.url} target="_blank">
                      {file.name}
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!, $lang: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        subtitle
        icon
        tag_cloud
        path
        lang
        img
        img_link
        one_page_scroll
        file {
          name
          url
        }
      }
      fields {
        slug
      }
      html
      rawMarkdownBody
    }
    company: allCompanyJson {
      nodes {
        text
        value
      }
    }
    email_dns: allEmailDnsJson {
      nodes {
        text
        value
      }
    }
    metaverse: allMetaverseJson {
      nodes {
        text
        value
      }
    }
    observability: allObservabilityJson {
      nodes {
        text
        value
      }
    }
    traffic_engineering: allTrafficEngineeringJson {
      nodes {
        text
        value
      }
    }
    ai_ml: allAiMlJson {
      nodes {
        text
        value
      }
    }
    allMainMenuJson(filter: { lang: { eq: $lang } }) {
      edges {
        node {
          name
          url
          lang
          submenu {
            lang
            name
            url
            anchors {
              lang
              name
              url
            }
            url
          }
        }
      }
    }
    allFooterMenuJson(filter: { lang: { eq: $lang } }) {
      edges {
        node {
          name
          url
          lang
          submenu {
            lang
            name
            anchors {
              lang
              name
              url
            }
            url
          }
        }
      }
    }
    allServiceMenuJson(filter: { lang: { eq: $lang } }) {
      edges {
        node {
          name
          url
          lang
          submenu {
            lang
            name
            png_icon
            url
          }
        }
      }
    }
    allSubFooterMenuJson(filter: { lang: { eq: $lang } }) {
      edges {
        node {
          name
          url
          lang
        }
      }
    }
  }
`;

export default Basic;
