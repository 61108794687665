export const options = {
  colors: ["#99ccff", "#7aa9db", "#5b88b8", "#3d6896", "#1d4a75"],
  enableOptimizations: true,
  enableTooltip: false,
  deterministic: false,
  fontFamily: "impact",
  fontSizes: [18, 50],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 4,
  rotations: 1,
  rotationAngles: [0, 90],
  scale: "sqrt",
  spiral: "rectangular",
  transitionDuration: 1000,
};
